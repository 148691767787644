import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div
        className="container-xl"
        style={{
          fontFamily: `sans-serif`,
          margin: `0 auto`,
        }}>
      <h1>404: Not Found</h1>
      <p>Wrong turn! Let us get back on track.</p>
    </div>
  </Layout>
)

export default NotFoundPage
